import '../styles/styles.scss';

import Swiper from 'swiper';
// import Swiper styles
import 'swiper/css';
window.Swiper = Swiper

import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
window.Alpine = Alpine
Alpine.plugin(collapse)
Alpine.start()


// import '../../node_modules/prettier-plugin-css-order/src/main.mjs';
// import '../../node_modules/prettier-plugin-tailwindcss/dist/index.mjs';


// import App from './app';


import './general';
// import './swiper';


// App.init();
//
